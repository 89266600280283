import dynamic from 'next/dynamic';

import * as React from 'react';
import {
  AlgoliaList,
  AlgoliaInstantSearchUrl,
  BreakingNewsBox,
  Abner,
  Aharoni,
  Beavis,
  // Bender,
  // Boxy,
  BoxyInverseLabels,
  Butthead,
  Calculon,
  // Clampazzo,
  // ClickTrackerWrapper,
  Conrad,
  // Donatello,
  Donbot,
  // ElementGroup,
  Elzar,
  Error,
  TmFooter,
  Flexo,
  Fry,
  // Gamal,
  AdSlot,
  AdSlotPrioritySelector,
  // GridContainer,
  // GridElement,
  Hawking,
  HtmlElement,
  HtmlNode,
  // Iframe,
  // Interactive,
  // Kroker,
  LeonardoTm,
  List,
  MainBlockTm,
  Marco,
  Masthead,
  Nibbler,
  // Michelangelo,
  // MobileListWrapper,
  // Mom,
  Morbo,
  // Mousepad,
  OutbrainSectionPage,
  Panucci,
  Pazuzu,
  PazuzuLabels,
  Kariv,
  Roberto,
  // RssFeed,
  Santa,
  SantaLabels,
  Schoonhoven,
  // Slim,
  // Slugs,
  Spawn,
  Tate,
  TateLabels,
  Inez,
  // TabElement,
  // TopNews,
  // Vogel,
  // Weekend,
  Petunia,
  Wong,
  Zapp,
  ZappLabels,
  // Zombie,
  LayoutContainer,
  SectionPageTitle,
  NewsletterServiceMessage,

  // icons
  IconMarkerLogo,
  IconMarkerLogoTransparent,
  IconTheMarkerLabel,

  // utils
  getElementsFactory,
} from '@haaretz/htz-components';

const ImageGallery = dynamic(() => import('../components/ImageGallery/ImageGallery'));

function MastheadLogo() {
  return (
    <IconMarkerLogo
      color="primary"
      size={3.5}
      miscStyles={{
        display: 'block',
      }}
    />
  );
}

// eslint-disable-next-line react/prop-types
function MastheadLabelLogo() {
  return (
    <IconTheMarkerLabel
      attrs={{ 'aria-hidden': true, }}
      size={[ { until: 'l', value: 5, }, { from: 'l', value: 5.5, }, ]}
      color={[ 'bodyText', 'base', ]}
      fill="black"
      miscStyles={{
        display: 'block',
      }}
    />
  );
}

// eslint-disable-next-line react/prop-types
function SectionPageBreakingNews({ properties, ...props }) {
  return (
    <LayoutContainer miscStyles={{ paddingBottom: [ { from: 's', value: '4rem', }, ], }}>
      <BreakingNewsBox {...properties} {...props} />
    </LayoutContainer>
  );
}
const elements = new Map([
  // General components
  // [ 'com.tm.element.AlgoliaIntantSearch', AlgoliaInstantSearchUrl, ],
  // [ 'com.tm.layout.element.BreakingNewsListElement', SectionPageBreakingNews, ],
  [ 'PageNavigation', Masthead, ],
  [ 'MainBlock', MainBlockTm, ],
  // [ 'com.tm.element.SearchResultsList', AlgoliaList, ],
  // // [ 'com.polobase.ClickTrackerBannersWrapper', ClickTrackerWrapper, ],
  [ 'AdSlot', AdSlot, ],
  [ 'AdSlotPrioritySelector', AdSlotPrioritySelector, ],
  [ 'AdSlotRichTextElement', AdSlot, ],
  [ 'Outbrain', OutbrainSectionPage, ],
  // // [ 'com.polobase.whtzMobileSiteListsWrapper', MobileListWrapper, ],
  // // [ 'com.tm.ElementGroup', ElementGroup, ],
  // // [ 'com.tm.ExternalRssElement', RssFeed, ],
  [ 'PageFooter', TmFooter, ],
  // // [ 'com.tm.GridElementGroup', GridElement, ],
  // // [ 'com.tm.GridContainer', GridContainer, ],
  // // [ 'com.tm.HeaderNewsGroup', TopNews, ],
  [ 'HtmlElement', HtmlElement, ],
  [ 'StaticElement', HtmlElement, ],
  // // [ 'com.tm.IframElement', Iframe, ],
  // [ 'com.tm.ResubscribeElementsGroup', ],
  // // [ 'com.tm.TabViewElement', TabElement, ],
  [ 'htz_list_List', List, ],
  // [ 'com.tm.promotion.banner.BottomRuler', ],
  // // [ 'com.tm.promotion.banner.MiddleRuler', MarketingNotification, ],
  // [ 'com.tm.promotion.banner.TopRuler', ],
  // [ 'com.tm.PageTitle', SectionPageTitle, ],
  // [ 'interactive', Interactive, ],
  // [ 'com.tm.ArticleInteractiveHtmlElement', Interactive, ],
  [ 'NewsletterPageMessage', NewsletterServiceMessage, ],
  [ 'HtmlNode', HtmlNode, ],
  [ 'error', Error, ],
  [ 'imageGalleryTeaser', ImageGallery, ],

  // list views
  [ 'Abner', Abner, ],
  [ 'Beavis', Beavis, ],
  // [ 'Bender', Bender, ],
  // [ 'Boxy', Boxy, ],
  [ 'Butthead', Butthead, ],
  [ 'BoxyInverse_Labels', BoxyInverseLabels, ],
  [ 'Calculon', Calculon, ],
  // [ 'Clampazzo', Clampazzo, ],
  [ 'Conrad', Conrad, ],
  // [ 'Donatello', Donatello, ],
  [ 'Donbot', Donbot, ],
  [ 'Hawking', Hawking, ],
  [ 'Flexo', Flexo, ],
  // [ 'Gamal', Gamal, ],
  // [ 'Kroker', Kroker, ],
  [ 'Leonardo', LeonardoTm, ],
  // [ 'Michelangelo', Michelangelo, ],
  // [ 'Mom', Mom, ],
  // [ 'Mousepad', Mousepad, ],
  [ 'Morbo', Morbo, ],
  [ 'Nibbler', Nibbler, ],
  [ 'Panucci', Panucci, ],
  [ 'Pazuzu', Pazuzu, ],
  [ 'Pazuzu_Labels', PazuzuLabels, ],
  [ 'Roberto', Roberto, ],
  [ 'Elzar', Elzar, ],
  [ 'Santa', Santa, ],
  [ 'Santa_Labels', SantaLabels, ],
  [ 'Schoonhoven_Labels', props => <Schoonhoven {...props} viewProps={{ ...(props.viewProps || {}), isCommercial: true, }} />, ],
  // [ 'Slim', Slim, ],
  [ 'Tate', Tate, ],
  [ 'Tate_Labels', TateLabels, ],
  [ 'Spawn', Spawn, ],
  // [ 'Spawn', Spawn, ],
  // [ 'Weekend', Weekend, ],
  [ 'Wong', Wong, ],
  [ 'Zapp', Zapp, ],
  [ 'Zapp_Labels', ZappLabels, ],
  // [ 'Zombie', Zombie, ],
  // [ 'Vogel', Vogel, ],
  [ 'Fry', Fry, ],
  [ 'Inez', Inez, ],
  [ 'Petunia', Petunia, ],
  [ 'Kariv', Kariv, ],

  // Algolia list-views
  [ 'Marco', Marco, ],
  [ 'Aharoni', Aharoni, ],
  [ 'mastheadLogo', MastheadLogo, ],
  [ 'mastheadLabelLogo', MastheadLabelLogo, ],
  [ 'siteLogo', IconMarkerLogo, ],
  [ 'siteIconTransparent', IconMarkerLogoTransparent, ],
]);

const getArticleElements = getElementsFactory(elements);

export default getArticleElements;
